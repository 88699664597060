<template>
  <div class="video-detail">
    <div class="video-detail-left">
      <iframe
        width="100%"
        height="55%"
        :src="detailObj.iframeUrl"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>

      <div class="video-title">
        <div class="video-title-a">
          <a :href="detailObj.iframeUrl" target="_blank">
            {{ detailObj.title }}
          </a>
        </div>
        <div>
          <el-button
            type="info"
            class="el-icon-star-on"
            v-if="detailObj.collectId"
            style="background:#ED7BAB;border:#ED7BAB"
            @click="clearCollection"
          >
            取消收藏
          </el-button>
          <el-button
            type="primary"
            class="el-icon-star-on"
            style="background:#ED7BAB;border:#ED7BAB"
            @click="collection"
            v-else
          >
            收 藏
          </el-button>
        </div>
      </div>
      <div class="video-time">
        <span>{{ detailObj.viewCount }}次观看</span>
        <span class="modification"></span>
        <span>
          {{ detailObj.publishDate }}
        </span>
      </div>
      <div class="video-memo">
        <div class="video-user-photo">
          <img :src="detailObj.videoThumbnail" alt="" />
        </div>
        <div class="video-sketch">
          <div class="video-detail-info">
            <span class="u-name">{{ detailObj.author }}</span>
            <!-- <span class="u-num">15.1万位订阅者</span> -->
          </div>
          <div class="u-text">
            {{ detailObj.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="video-detail-right">
      <div
        class="comment-list"
        v-infinite-scroll="load"
        :infinite-scroll-disabled="scrollDisabled"
        :infinite-scroll-distance="10"
      >
        <ul>
          <li v-for="(item, i) in commentList" :key="i">
            <div class="comment-item-parent">
              <img :src="item.authorThumbnail" alt="" />
              <div>
                <div class="comment-parent-name">
                  <span>{{ item.author }}</span>
                  <span>{{ item.publishDate }}</span>
                </div>
                <div class="comment-parent-title">{{ item.contentText }}</div>
                <el-collapse accordion v-if="item.replies">
                  <el-collapse-item>
                    <template slot="title">
                      <span class="collapse-title">
                        共{{ item.replies.length }}条评论
                      </span>
                    </template>
                    <div>
                      <ul>
                        <li v-for="(ele, j) in item.replies" :key="j">
                          <div class="comment-item-parent">
                            <img
                              class="child-img"
                              :src="ele.authorThumbnail"
                              alt=""
                            />
                            <div>
                              <div class="comment-child-name">
                                <span>{{ ele.author }}</span>
                                <span>{{ ele.publishDate | dateChange }}</span>
                              </div>
                              <div class="comment-parent-title">
                                {{ ele.contentText }}
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </li>
          <li v-if="noMore" class="comment-nomore">加载中...</li>
          <li v-else class="comment-nomore">没有更多了...</li>
        </ul>
      </div>
      <noData v-if="commentList.length == 0" text="暂无评论">
        <div style="margin-top:20px">
          <el-button type="primary" size="mini" @click="Refresh">
            重载评论
          </el-button>
        </div>
      </noData>
      <!-- <pagination
        class="channel-pagination-child"
        :total="total"
        :page-size="limit"
        :currentPage="current"
        :pagerCount="5"
        background
        :sizesArr="[18, 36, 72]"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      /> -->
    </div>
  </div>
</template>

<script>
import {
  videoDetail,
  videoComment,
  collect,
  clearCollect,
  commentOverload
} from '@/api/channel.js'
// import { getIdFromURL, getTimeFromURL } from 'vue-youtube-embed'
export default {
  data() {
    return {
      autoplay: 0,
      detailObj: {},
      commentList: [],
      // 当前页
      current: 1,
      // 每页记录数
      limit: 30,
      // 总记录数
      total: 0,

      videoID: '',
      noMore: true,
      scrollDisabled: false
    }
  },
  filters: {
    dateChange(val) {
      if (val) {
        if (val.split('T').length > 0) {
          let year = val.split('T')[0]
          let time = val.split('T')[1].split('.')[0]
          return year + ' ' + time
        }
      }
    }
    // urlChange: getIdFromURL
  },
  created() {
    let id = this.$route.params.videoId
    this.videoID = id
    if (id) {
      this.getData(id)
      this.getComment(id)
    } else {
      this.$router.go(-1)
    }
  },
  methods: {
    // 收藏
    collection() {
      let obj = {}
      obj.videoId = this.detailObj.videoId
      obj.title = this.detailObj.title
      collect(obj).then(data => {
        if (data.id) {
          this.$message({
            type: 'success',
            message: '收藏成功！'
          })
          // this.detailObj.collectId = data.id
          this.$set(this.detailObj, 'collectId', data.id)
        }
      })
    },
    // 取消收藏
    clearCollection() {
      clearCollect(this.detailObj.collectId).then(data => {
        this.$message({
          type: 'success',
          message: '取消收藏！'
        })
        this.detailObj.collectId = null
      })
    },
    //   滚动加载
    load() {
      this.getComment(this.videoID)
    },
    getComment(id) {
      this.scrollDisabled = true
      videoComment(this.current, this.limit, id).then(data => {
        let arr = data.map(item => {
          if (item.replies) {
            item.replies = JSON.parse(item.replies)
          }
          return item
        })
        if (arr.length > 0) {
          this.current++
          this.scrollDisabled = false
          this.commentList = this.commentList.concat(arr)
          if (arr.length < this.limit) {
            this.noMore = false
          }
        } else {
          this.noMore = false
        }
      })
    },
    // 评论重载
    Refresh() {
      this.getCommentOverload()
    },
    getCommentOverload() {
      commentOverload(this.detailObj.videoId).then(data => {
        this.$message({
          type: 'success',
          message: data
        })
      })
    },
    getData(id) {
      videoDetail(id).then(data => {
        this.detailObj = Object.assign({}, data)

        // let url = this.detailObj.iframeUrl.replace('watch?v=', 'embed/')
        // url = this.detailObj.iframeUrl.replace('https', 'http')
        // this.detailObj.iframeUrl =
        //   url +
        //   '?ecver=1&amp;autoplay=1&amp;iv_load_policy=1&amp;rel=0&amp;yt:stretch=16:9&amp;autohide=0&amp;color=red'
      })
    },
    // 评论分页
    currentChange(val) {
      this.current = val
      this.getList()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.video-detail {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px 0 10px 20px;
  display: flex;
  justify-content: space-between;
  .video-detail-left {
    width: 55%;
    height: 100%;

    padding-right: 5px;
    iframe {
      width: 100%;
      height: 55%;
    }
    .video-title {
      display: flex;
      justify-content: space-between;
      > div:nth-child(1) {
        width: calc(100% - 120px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */

        font-size: 18px;

        font-weight: bold;
        color: #222222;
        line-height: 24px;
      }
      > div:nth-child(2) {
      }
      .video-title-a a:hover {
        color: #0886ee;
      }
    }
    .video-time {
      span {
        font-size: 14px;
        font-weight: 300;
        color: #666666;
        line-height: 50px;
      }
      .modification {
        display: inline-block;
        width: 2px;
        height: 2px;
        background: #1a1a1b;
        border-radius: 50%;
        vertical-align: middle;
        margin: 0 5px;
      }
    }
    .video-memo {
      border-top: 1px solid rgb(182, 180, 180);
      padding-top: 10px;
      height: 33%;

      display: flex;
      .video-user-photo {
        img {
          width: 48px;
          height: 48px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .video-sketch {
        margin-left: 10px;
        height: 100%;
        width: calc(100% - 50px);
        .video-detail-info {
          white-space: nowrap;
          .u-name {
            font-size: 14px;

            font-weight: 600;
            color: #222222;
            line-height: 50px;
          }
          .u-num {
            font-size: 12px;
            margin-left: 10px;
            font-weight: 400;
            color: #666666;
            line-height: 50px;
          }
        }
        .u-text {
          height: calc(100% - 60px);
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          color: #222222;
          line-height: 24px;
        }
      }
    }
  }
  .video-detail-right {
    width: 44.5%;
    height: 100%;
    padding: 0 10px;
    position: relative;
    .comment-list {
      width: 100%;
      height: calc(100% - 0px);
      overflow: auto;
      ul {
        margin: 0;
        padding: 0;
      }
      li {
        list-style: none;
        margin: 10px 0;
      }
      .comment-item-parent {
        display: flex;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .child-img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        > div {
          margin-left: 10px;
          .comment-parent-name {
            > span:nth-child(1) {
              font-size: 13px;
              font-weight: bold;
              color: #1a1a1b;
              line-height: 40px;
            }
            > span:nth-child(2) {
              font-size: 12px;
              margin-left: 10px;
              font-weight: 300;
              color: #606060;
              //   line-height: 50px;
            }
          }
          .comment-parent-title {
            font-size: 12px;

            font-weight: 300;
            color: #1a1a1a;
            line-height: 22px;
          }
          .comment-child-name {
            line-height: none;
            font-weight: 600;
            color: #606060;
            > span:nth-child(1) {
              font-size: 13px;
              font-weight: bold;
              color: #1a1a1b;
            }
            > span:nth-child(2) {
              font-size: 12px;
              margin-left: 10px;
              font-weight: 300;
              color: #606060;
              //   line-height: 50px;
            }
          }
        }
      }
    }
  }
  .collapse-title {
    color: #0886ee;
  }
  ::v-deep .el-collapse-item__wrap,
  ::v-deep .el-collapse-item__header {
    background: none;
    border: none;
  }
  ::v-deep .el-collapse-item__arrow {
    color: #0886ee;
    margin: 0;
  }
  ::v-deep .el-collapse {
    border: none;
  }
  .comment-nomore {
    font-size: 14px;
    color: #606060;
    text-align: center;
  }
}
</style>
